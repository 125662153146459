// IMPORT LIBRARIES
import Vue from 'vue';
import 'sharer.js';
import objectFitImages from 'object-fit-images';
import LazyLoad from "vanilla-lazyload";

// IMPORT CUSTOM FILES
import './navigation';
import './modernizr';

// COMPONENTS
import InstagramFeature from "./components/InstagramFeature";

(function() {
    const vm = new Vue({
        el: '#app',
        delimiters: ['${', '}'],
        components: {
            InstagramFeature
        },
    });
}());

//initialize object fit polyfill
objectFitImages();

const lazyLoadInstance = new LazyLoad({});

// re-instantiate lazy load on document complete
if (document.readyState === "complete") {
    lazyLoadInstance.update();
}
