<template>
    <div class="insta-wrapper">

        <div id="instagramGrid" class="grid-wrapper">
            <div v-for="(post, index) in instagramPosts" class="flex-item">

                <a v-if="post.media_type != 'VIDEO'" :href="post.permalink" target="_blank" rel="noopener">
                    <img :src="post.media_url" :alt="'Instagram Post: ' + post.permalink">
                </a>

                <a v-if="post.media_type == 'VIDEO'" :href="post.permalink" target="_blank">
                    <img :src="post.thumbnail_url" :alt="'Instagram Post: ' + post.permalink">
                </a>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            accesstoken: {
                default: '',
                type: String
            },

        },
        data() {
            return {
                instagramPosts: [],
                limit: 12
            }
        },
        beforeMount() {
            this.getInstagramFeed();

        },
        methods: {
            getInstagramFeed() {
                const instagramFields = 'id,username,caption,media_type,media_url,permalink,thumbnail_url';
                const apiRequestUrl =  "https://graph.instagram.com/me/media?fields=" + instagramFields + "&access_token=" + this.accesstoken;

                let self = this;
                let xhr = new XMLHttpRequest();
                xhr.open('GET', apiRequestUrl);
                xhr.onload = function() {
                    if (xhr.status === 200) {
                        const data = xhr.responseText;
                        const jsonResponse = JSON.parse(data);

                        // console.log(jsonResponse['data'])
                        self.instagramPosts = jsonResponse['data'].slice(0, self.limit);
                    }
                };
                xhr.send();
            }

        }
    }
</script>
