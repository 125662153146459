import {
    toggleClass,
    removeClass,
    toggleAriaExpanded
} from './helpers.js';

const mobileMenuToggle = document.getElementById('menuBtn'),
      mobileMenu = document.getElementById('primaryNav'),
      dropDownToggle = document.querySelectorAll('.arrow-wrapper'),
      dropdownItems = document.querySelectorAll('.has-children');

window.addEventListener('resize', closeMobileNavigationOnResize);

mobileMenuToggle.addEventListener("click", function(event) {
    event.preventDefault();

    // remove attributes and classes from children before closing menu
    if ( mobileMenu.classList.contains('open') ) {
        dropdownItems.forEach( element => element.classList.remove('open') );
        dropdownItems.forEach( element => element.setAttribute('aria-expanded', 'false') );
    }

    toggleClass(mobileMenu, 'open');
    toggleAriaExpanded(mobileMenu);
});

dropDownToggle.forEach( function (element) {
    element.addEventListener("click", function(event) {
        event.preventDefault();
        let parentElement = element.closest('.has-children');

        // disable all dropdowns not clicked
        dropdownItems.forEach( element =>
            element.closest('.has-children') === parentElement ?
            null :
            element.setAttribute('aria-expanded', 'false') + removeClass(element, 'open')
        );

        toggleClass(parentElement, 'open');
        toggleAriaExpanded(parentElement);
    })
}
);

function closeMobileNavigationOnResize() {
    if ( window.innerWidth > 800 ) {
        removeClass(mobileMenu, 'open');
        dropdownItems.forEach( element =>
            element.classList.remove('open')
        );
    }

}

