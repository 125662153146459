export function toggleClass(element, className) {

    if ( ifContainsClass(element, className) ) {
        removeClass(element, className);

    } else {
        addClass(element, className);

    }
}

export function ifContainsClass(element, className) {
    return element.classList.contains(className);
}

export function addClass(element, className) {
    element.classList.add(className);
}

export function removeClass(element, className) {
    element.classList.remove(className);
}

export function toggleAriaExpanded(element) {
    if ( element.getAttribute('aria-expanded') === 'true' ) {
        element.setAttribute('aria-expanded', 'false');

    } else {
        element.setAttribute('aria-expanded', 'true');
    }

}
